<template>
  <div>
    <Toolbar />
    <Menu />
  </div>
</template>

<script>
const Toolbar = () => import('./Toolbar')
const Menu = () => import('./MenuMini')
export default {
    name: 'Sidebar',
    components:{
        Toolbar,
        Menu
    }
}
</script>